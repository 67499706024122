import React, { useEffect } from 'react'
import styled , {keyframes} from 'styled-components'
import Aos from "aos"
import "aos/dist/aos.css"


const HomePage = styled.div`
display: flex;
justify-content: center;
flex-wrap: wrap;
align-items: center;
 background-color: #f7f7f2;
 height: 100vh;


`

  const HomeContainer = styled.div`
 margin-top: -200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content:center;
  align-items: center;
 text-align: center;
    
  `
   const typing = keyframes`
   from {    width: 0 }
   
   to { width: 100%; border:none; }

    `
       const typing2 = keyframes`
       
       from {   border:none; width: 0 }
       
       to { width: 100% }
        `
  const blinkTextCursor = keyframes`
   from{border-right-color: rgba(0,0,0)}
  to{border-right-color: transparent;}
   
    
  `
 const H1 = styled.div`
   color: #071013; 
   font-weight: 300;
   font-family: 'Ubuntu Mono', monospace;
   font-size: 24px;
   padding-bottom: 10px;
   white-space: nowrap;
   border-right: 2px solid rgba(0,0,0,.75);
    overflow: hidden;
    transform: translateY(-50%); 
   animation: ${typing} .8s steps(44)  1 normal both,
   ${blinkTextCursor} 900ms steps(44) 1; ;   

  `
     const H2 = styled.div`
     color: #071013;
     font-weight: 500;
     border-right: 2px solid rgba(0,0,0,.75);
     font-family: 'Ubuntu Mono', monospace;
     font-size: 39px;  
     white-space: nowrap;
       overflow: hidden;
       transform: translateY(-50%); 
       animation-delay: 1s !important;
      animation: ${typing2} 1.5s steps(44) 1 normal both,
      ${blinkTextCursor} 800ms steps(44) infinite;
              
   
     @media screen and (max-width: 960px) {
        font-size: 36px;
      
     }
    `




function Home() {

  useEffect(() => {
    Aos.init({duration: 1000,mirror: true});
  }, [])


    return (
         <HomePage id="H">
           
       <HomeContainer >
           <H1 >Hello. I am Osman </H1>
           <H2>Penetration Tester.</H2> 
           
       </HomeContainer>
     

       </HomePage>

    )
}

export default Home
