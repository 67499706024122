import React from "react";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import projects from "../Datas"

const ProjectPageContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #f7f7f2;
`;
const ProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
`;

const Heading = styled.div`
  font-size: 15px;
  display: flex;
  margin-top: 100px;
  text-align: center;
  justify-content: center;
  color: #071013;
`;
const SubHeading = styled.div`
  font-size: 59px;
  color: #071013;
`;
const ProjectBox = styled(LinkR)`
  width: 500px;
  height: 400px;
  display: flex;

  text-decoration: none;
  flex-direction: column;
  margin: 20px;
`;
const Images = styled.img`
  width: 100%;
  height: 500px;
  border-radius: 12px;
  overflow: hidden;
  object-fit: cover;
  display: inline-block;

  @media only screen and (max-width: 768px) {
    height: 350px;
    width: 100%;
  }
`;
const InfoBox = styled.div`
  
  margin-top: 10px;
  padding: 7px;
  border: 1px solid rgba(0,0,0,0.45);
  border-radius: 12px;
`;
const Desc = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 1rem;
  color: #071013;
`;
const Titles = styled.div`
  font-size: 27px;
  font-weight: 500;
  color: #071013;
`;

export default function ProjectsSection() {
  return (
    <>
      <ProjectPageContainer id="Projects">
        <Heading data-aos="flip-left" >Some of my recent works</Heading>
        <SubHeading data-aos="flip-left" >Projects</SubHeading>
        <ProjectContainer >
        {projects.map((data) => {
          return (
            <ProjectBox data-aos="zoom-in" key={data.id}  to={{ pathname: data.Url }}
            target="_blank"  >

            <Images src={data.img} alt="" />
            <InfoBox>
              <Titles>{data.name}</Titles>
              <Desc>{data.desc}</Desc>
            </InfoBox>
          </ProjectBox>
          )
        })}
         
          
          

      
        </ProjectContainer>
      </ProjectPageContainer>
    </>
  );
}
