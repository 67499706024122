import { v4 as uuidv4 } from 'uuid';
import AmazonImg from './images/amazon.png';
import NetflixImg from './images/netflix.png';
import CryptoImg from './images/crypto.png';

  const projects = [
    {
      id: uuidv4(),
      name: 'Cryptocurrency Tracker',
      desc:
        'A fully responsive website that you can see cryptocoins datas, graphs and related news. I built that app with using Reactjs, Redux, couple of APIs, styled components and Material UI.',
        img: CryptoImg,
    Url: "https://cryptoappv2.netlify.app/"
    },
    {
        id: uuidv4(),
        name: 'Netflix Clone',
        desc:
          'Netflix Homepage clone website. Built that with Reactjs, TMDB Api and Material UI.',
        img: NetflixImg,
        Url: "https://netflix-clonestdy.netlify.app/"
      },
      {
        id: uuidv4(),
        name: 'Amazon Clone',
        desc:
          'Amazon website clone with Homepage, Auth and chart sections. I used Reactjs, Context Api, Material UI.',
          img: AmazonImg,
          Url: "https://amazon-clonestdy.netlify.app"
      },
    ]
      export default projects;