import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "@formcarry/react";
import { FiPhone } from "react-icons/fi";
import { FaArtstation, FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";

const ContactContainer = styled.div`
  background-color: #f7f7f2;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 960px) {
    display: grid;
  }
`;
const Header = styled.div`
  background-color: #f7f7f2;
  padding: 100px 10px 0 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #071013;
  font-weight: 100;
  font-size: 39px;
  font-family: "Ubuntu Mono", monospace;
`;

const Button = styled.button`
  height: 40px;
  font-size: 1rem;
  width: 180px;
  margin: 15px;
  font-weight: 550;
  color: #f7f7f2;
  border: none;
  border-radius: 8px;
  outline: none;
  background-color: #394042;
  cursor: pointer;
  &:hover {
    background-color: #20282b;
  }
`;
const Left = styled.div`
  display: grid;
  height: 100%;
  width: 50%;
  padding: 30px 100px;

  .labels {
    display: flex;
    color: #071013;
    font-size: 24px;
    padding: 10px;
  }
  .inputs {
    border: 1px solid #071013;
    border-radius: 5px;
    display: flex;
    font-size: 24px;
    height: 50px;
    padding: 10px;
    width: 100%;
    margin: 10px;

    background-color: #f9f9f5;

    &::-webkit-input-placeholder {
      font-size: 2rem;
    }
  }
  .textarea {
    display: flex;
    font-size: 24px;
    margin-left: 10px;
    width: 100%;
    height: 150px;
    resize: none;
    outline: none;
    border: 1px solid #071013;
    border-radius: 5px;
    color: #071013;
    text-align: start;
    padding: 10px;
    background-color: #f9f9f5;
  }
`; // ----------------------------------------------------------------------------------------------
   // Second part copy of the page.. 
const Right = styled.div`
  display: grid;

  height: 100%;
  width: 50%;
  padding: 30px 100px;

  .labels {
    display: flex;
    color: #071013;
    font-size: 24px;
    padding: 10px;
  }
`;
const LinkBoxP = styled.div`
  margin: 0 0 15px 0;
  display: flex;
  color: #071013;
  padding: 10px;
  font-size: 24px;
  width: 100%;
  height: 50px;
  border: 1px solid #071013;
  border-radius: 5px;
  background-color: #f9f9f5;
`;

const LinkBox = styled(Link)`
  margin: 0 0 15px 0;
  display: flex;
  text-decoration: none;
  color: #071013;
  padding: 10px;
  font-size: 24px;
  width: 100%;
  height: 50px;
  border: 1px solid #071013;
  border-radius: 5px;
  background-color: #f9f9f5;
`;
const FiPhones = styled(FiPhone)`
  margin: 0 10px 0 10px;
`;
const FaArtstations = styled(FaArtstation)`
  margin: 0 10px 0 10px;
`;
const FaGithubs = styled(FaGithub)`
  margin: 0 10px 0 10px;
`;
const SubmitMessage = styled.div`

  font-size: 26px;
  margin-top: 10px;
  transition: all 0.5 ease;
  color: #36b37e;
`;

function Contact() {
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { state, submit } = useForm({
    id: "XoVl9KMVJ3s",
  });

  if (state.submitted) {
    return (
      <>
        <Header>Feel free to Contact Me!</Header>
        <ContactContainer id="Contact">
          <Left>
            <form onSubmit={submit}>
              <label className="labels" htmlFor="Name">
                Your Name
              </label>
              <input className="inputs" id="name" type="text" name="name" />

              <label className="labels" htmlFor="email">
                Email
              </label>
              <input
                className="inputs"
                id="email"
                type="email"
                name="email"
                value={value}
                onChange={handleChange}
              />

              <label className="labels" htmlFor="message">
                Message
              </label>
              <textarea
                className="textarea"
                rows="1"
                cols="50"
                wrap="physical"
                id="message"
                name="message"
              />
              <SubmitMessage>Your submission has been sent!</SubmitMessage>
              <Button disabled={!value} type="submit">
                Send Message!
              </Button>
            </form>
          </Left>

          <Right>
          
            
            <label className="labels" htmlFor="Name">
           
              Github
            </label>

            <LinkBox
              to={{ pathname: "https://github.com/Osmanilgin" }}
              target="_blank"
            >
              <FaGithubs />
              github.com/Osmanilgin
            </LinkBox>
            <label className="labels" htmlFor="Name">
          Linkedin
          </label>

          <LinkBox
            to={{ pathname: "https://www.linkedin.com/in/osmanilgin/" }}
            target="_blank"
          >
            <FaArtstations />
            linkedin.com/in/osmanilgin
          </LinkBox>
          <label className="labels" htmlFor="Name">
            Artstation
          </label>

          <LinkBox
              to={{ pathname: "https://www.artstation.com/stylzd" }}
              target="_blank"
            >
              <FaArtstations />
              artstation.com/stylzd
            </LinkBox>
            
          </Right>
        </ContactContainer>
      </>
    );
  }

  return (
    <>
      <Header >Feel free to Contact Me!</Header>
      <ContactContainer id="Contact">
        <Left>
          <form onSubmit={submit}>
            <label className="labels" htmlFor="Name">
              Your Name
            </label>
            <input className="inputs" id="name" type="text" name="name" />

            <label className="labels" htmlFor="email">
              Email
            </label>
            <input
              className="inputs"
              id="email"
              type="email"
              name="email"
              value={value}
              onChange={handleChange}
            />

            <label className="labels" htmlFor="message">
              Message
            </label>
            <textarea
              className="textarea"
              rows="1"
              cols="50"
              wrap="physical"
              id="message"
              name="message"
            />

            <Button disabled={!value} type="submit">
              Send Message!
              
            </Button>
           
          </form>
        </Left>
        <Right>
         
          <label className="labels" htmlFor="Name">
            {" "}
            Github
          </label>

          <LinkBox
            to={{ pathname: "https://github.com/Osmanilgin" }}
            target="_blank"
          >
            <FaGithubs />
            github.com/Osmanilgin
          </LinkBox>
          <label className="labels" htmlFor="Name">
          Linkedin
          </label>

          <LinkBox
            to={{ pathname: "https://www.linkedin.com/in/osmanilgin/" }}
            target="_blank"
          >
            <FaArtstations />
            linkedin.com/in/osmanilgin
          </LinkBox>
          <label className="labels" htmlFor="Name">
            Artstation
          </label>

          <LinkBox
              to={{ pathname: "https://www.artstation.com/stylzd" }}
              target="_blank"
            >
              <FaArtstations />
              artstation.com/stylzd
            </LinkBox>
      
        </Right>
      </ContactContainer>
    </>
  );
}

export default Contact;
/**/
