import React from "react";
import styled from "styled-components";
import AboutImages from "../images/pp.png";




const AboutContainer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  height: 80vh;
  background-color: #f7f7f2;

  @media screen and (max-width: 960px) {
    height: 100%;
  }
`



const Desc = styled.div`
  width: 40%;
  font-size: 22px;
  color: #071013;
  justify-content: center;
  text-align: center;
  display: flex;
  margin: 90px 90px 200px 0;

  @media screen and (max-width: 1330px) {
    margin: 0 10px 200px 10px;
    width: 90%;
  }
`;
const AboutImage = styled.img`
  height: 300px;
  width: 300px;
  border-radius: 50%;
  object-fit: cover;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

function About() {


  return (
    <>
         
      <AboutContainer id="About">
   
        <Desc data-aos="fade-right" >
         
          Under development part :D
     
        </Desc>
        

      {/* <AboutImage data-aos="fade-left" src={AboutImages} /> */}
   
      </AboutContainer>  
    </>
  );
}

export default About;
