import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../images/Logo.png";
import { Link as LinkS } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";

const Nav = styled.div`
  height: 80px;
  display: flex;
  padding: 50px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #f7f7f2;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

const NavLogo = styled.img`
  filter: invert(5%) sepia(10%) saturate(2291%) hue-rotate(149deg)
    brightness(92%) contrast(99%);
  display: flex;
  height: 10vh;
  width: 120px;
  justify-self: flex-start;
  cursor: pointer;
  align-items: center;
  margin-top: 8px;
  text-decoration: none;

  @media screen and (max-width: 960px) {
    width: 130px;
    margin: -10px 0 0 -75px;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #bcb4b4;
  }
`;
export const NavMenu = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95vh;
    font-size: 2rem;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.6s ease;
    background: #f7f7f2;
  }
`;
export const NavItem = styled.li`
  height: 80px;
  border-bottom: 2px solid transparent;

  @media screen and (max-width: 960px) {
    width: 100%;

    &:hover {
      border: none;
    }
  }
`;
export const NavLinks = styled(LinkS)`
  color: #071013;
  display: flex;
  font-weight: 300;
  font-family: "Ubuntu Mono", monospace;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1.8rem;
  height: 100%;
  cursor: pointer;
  font-size: 24px;
  &:hover {
    color: #071013;
    font-size: 26px;
    transition: 0.1s all ease-in-out;
    border-bottom: 1px solid #071013;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #9a9a9a;
      transition: all 0.3s ease;
    }
  }
`;

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <Nav>
      <NavbarContainer>
        <LinkS
          to="H"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-80}
        >
          <NavLogo src={logo} />{" "}
        </LinkS>

        <MobileIcon onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </MobileIcon>

        <NavMenu onClick={handleClick} click={click}>
          <NavItem>
            <NavLinks
              to="About"
              onClick={handleClick}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-200}
            >
              About
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="Projects"
              onClick={handleClick}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-30}
            >
              Projects
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="Contact"
              onClick={handleClick}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Contact
            </NavLinks>
          </NavItem>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
}

export default Navbar;
