import Home from './components/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import GlobalStyle from './globalStyles';
import {BrowserRouter as Router, Switch, Route} from  'react-router-dom'

function App() {
  return (
  <>
   <Router>
   <GlobalStyle/>
   <Navbar/>
  
    <Home />
   <About/>
  
    <Contact/>
   </Router>
  </>
  );
}

export default App;
